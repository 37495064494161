import { PageProps } from 'gatsby'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useAsync } from 'react-use'
import styled from 'styled-components/macro'
import { getBugBounty } from '../../apiClients/events'
import Heading from '../../components/Heading'
import Layout from '../../components/Layout'
import { LayoutContainer } from '../../components/LayoutContainer'
import { MarkdownRenderer } from '../../components/MarkdownRenderer'
import Spinner from '../../components/Spinner'
import StoreItemHeader, {
	propsFromEvent,
} from '../../components/StoreItemHeader'
import { useAppContext } from '../../context'
import Breadcrumb from '../../models/Breadcrumb'
import Entity from '../../models/Entity'
import Event from '../../models/Event'
import { SettingKey } from '../../models/GlobalSetting'
import { buildMetaTitle } from '../../utils/meta'

export default function BugBounties({ params, location }: PageProps) {
	const [bounty, setBounty] = useState<Entity<Event>>()
	const [loading, setLoading] = useState(true)
	const { getSettingValue } = useAppContext()

	useAsync(async () => {
		setLoading(true)
		const response = await getBugBounty(params.urlPath)
		if (response) setBounty(response)
		setLoading(false)
	}, [params.urlPath])

	const firstLevel = location.pathname.split('/').filter(p => p)[0]

	const breadcrumbs: Breadcrumb[] = [
		{
			link: `/${firstLevel}`,
			featureName: firstLevel.includes('archive')
				? 'bug-bounties-archive'
				: 'bug-bounties',
		},
		{
			link: location.pathname,
			display: bounty?.attributes.name ?? params.urlPath,
		},
	]

	const header = bounty ? (
		<StoreItemHeader {...propsFromEvent(bounty.attributes)} />
	) : null

	const metaTitle = getSettingValue(SettingKey.META_TITLE)

	return (
		<Layout breadcrumbs={breadcrumbs}>
			<Helmet>
				<title>{buildMetaTitle([bounty?.attributes.name, metaTitle])}</title>
			</Helmet>
			<Container>
				{loading && !bounty ? (
					<Spinner />
				) : bounty ? (
					<>
						{bounty.attributes.markdown && (
							<MarkdownRenderer
								header={header}
								children={bounty.attributes.markdown}
							/>
						)}
						{bounty.attributes.writeUp && (
							<MarkdownRenderer
								header={bounty.attributes.markdown ? null : header}
								children={bounty.attributes.writeUp}
							/>
						)}
					</>
				) : (
					<Heading>Bug Bounty not found</Heading>
				)}
			</Container>
		</Layout>
	)
}

const Container = styled(LayoutContainer)`
	display: flex;
	flex-direction: column;
	gap: 20px;
`
